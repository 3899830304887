import { ITokenInfo } from "types/settings.type";
import { useCallback, useEffect, useState } from "react";

export const useTokenListStorage = () => {
  const [tokens, setTokens] = useState<ITokenInfo[]>([]);

  useEffect(() => {
    const currentList = localStorage.getItem("straxDashboard-token");
    const parsedList = currentList ? JSON.parse(currentList) : [];
    setTokens(parsedList);
  }, []);

  const addToken = useCallback(
    (tokenItem: any) => {
      const updatedList = [...tokens, tokenItem];
      localStorage.setItem("straxDashboard-token", JSON.stringify(updatedList));
      setTokens(updatedList);
    },
    [tokens]
  );

  const removeToken = useCallback(
    (address: string) => {
      const updatedList = tokens.filter((item) => item.tokenAddress !== address);
      localStorage.setItem("straxDashboard-token", JSON.stringify(updatedList));
      setTokens(updatedList);
    },
    [tokens]
  );

  return { tokens, addToken, removeToken };
};
