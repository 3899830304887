"use client";
import { Icon } from "@iconify/react/dist/iconify.js";

type Props = {
  link: string;
};
const AppLinkFooter = ({ link }: Props) => {
  return (
    <footer className="flex justify-center w-full">
      <a
        href={link}
        target="_blank"
        className="relative flex items-center gap-4 text-white hover:text-theme-main u-transition-color group"
      >
        <span className="absolute top-0 left-0 w-full h-full bg-white/20 group-hover:bg-theme-main/30 u-transition-color animate-pingSlow  blur-lg pointer-events-none"></span>
        Official website <Icon icon={"material-symbols-light:arrow-upward"} className="rotate-45" />
      </a>
    </footer>
  );
};

export default AppLinkFooter;
