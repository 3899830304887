import { wagmiConfig } from "App";
import { readContract } from "@wagmi/core";
import { erc20Abi } from "viem";
import { stratis } from "viem/chains";

export const getTokenInfo = async (address: string) => {
  try {
    const symbol = await readContract(wagmiConfig, {
      address: address as `0x${string}`,
      abi: erc20Abi,
      functionName: "symbol",
      chainId: stratis.id,
    });

    const decimals = await readContract(wagmiConfig, {
      address: address as `0x${string}`,
      abi: erc20Abi,
      functionName: "decimals",
      chainId: stratis.id,
    });

    return {
      symbol: symbol || "",
      decimals: decimals || 0,
    };
  } catch (error) {
    return;
  }
};
