import { auroria, stratis } from "viem/chains";
import { useAccount } from "wagmi";

const NetworkWarning = () => {
  const { address, chainId } = useAccount();
  return (
    <>
      {address && chainId !== stratis.id && (
        <div
          className={`w-full p-12 text-center ${
            chainId === auroria.id ? "text-theme-main bg-theme-main/10" : "text-red-600 bg-rose-500/10"
          }`}
        >
          {chainId === auroria.id ? "This is Auroria Testnet" : "Wrong Network"}
        </div>
      )}
    </>
  );
};

export default NetworkWarning;
