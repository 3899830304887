import { useCallback } from "react";
import { erc20Abi } from "viem";
import { stratis } from "viem/chains";
import { type BaseError, useAccount, useWaitForTransactionReceipt, useWriteContract } from "wagmi";

export function useSendToken() {
  const { address, chain } = useAccount();

  const { data: hash, error, isPending, writeContract } = useWriteContract();
  const { isLoading, isSuccess: isConfirmed } = useWaitForTransactionReceipt({ hash });

  const sendToken = useCallback(
    async (recipient: string, amount: BigInt, tokenAddress: string) => {
      if (!address || !chain) {
        console.log("error");
        return;
      }
      writeContract({
        address: tokenAddress as `0x${string}`,
        abi: erc20Abi,
        functionName: "transfer",
        args: [recipient as `0x${string}`, amount as bigint],
        account: address,
        chain: chain || stratis,
      });
    },
    [address, chain, writeContract]
  );
  const errorMessage = (error as BaseError)?.shortMessage || error?.message;

  return { pending: isPending || isLoading, isConfirmed, error: errorMessage, sendToken };
}
