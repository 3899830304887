import PortfolioContent from "./components/PortfolioContent";

const PortfolioPage = () => {
  return (
    <section className="relative p-32 lg:p-8 lg:py-92 min-h-screen flex flex-col justify-between gap-32">
      <PortfolioContent />
    </section>
  );
};

export default PortfolioPage;
