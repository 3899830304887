"use client";
import { useEffect, useState } from "react";
import TokenImportModal from "./TokenImportModal";
import { useTokenListStorage } from "hooks/settings/useTokenListStorage";
import { Icon } from "@iconify/react/dist/iconify.js";
import { shortenString } from "utils/string.util";

const TokenSetting = () => {
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const { tokens, addToken, removeToken } = useTokenListStorage();

  useEffect(() => {
    setIsImportModalOpen(isImportModalOpen);
  }, [isImportModalOpen]);

  return (
    <>
      <div className="g-border-pink p-1 max-w-480 w-full mx-auto rounded-16 overflow-hidden">
        <div className=" flex flex-col gap-24 bg-primary-400 shadow-sm rounded-16 p-24 sm:p-16 border border-white/5">
          <div className="border-b border-white/5 font-medium text-white pb-8 px-4 w-full text-18 text-center">
            Custom Tokens
          </div>
          {!tokens?.length ? (
            <div className="text-center text-14 text-primary-200">No Custom Token Added</div>
          ) : (
            <table>
              <tbody>
                {tokens.map((token, i) => {
                  return (
                    <tr key={i} className="text-14 ">
                      <td className="p-4">{token.tokenName}</td>
                      <td className="p-4">{token.tokenSymbol}</td>
                      <td className="p-4">{shortenString(token.tokenAddress, 8, 6)}</td>
                      <td className="p-4 text-right">
                        <button onClick={() => removeToken(token.tokenAddress)}>
                          <Icon icon={"ep:circle-close"} className="w-18 h-18 text-red-600" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
          <button className="m-button-primary mx-auto w-fit" onClick={() => setIsImportModalOpen(true)}>
            Import Token
          </button>
        </div>
      </div>
      <TokenImportModal
        isOpen={isImportModalOpen}
        onClose={() => setIsImportModalOpen(false)}
        onSubmit={addToken}
        tokens={tokens}
      />
    </>
  );
};

export default TokenSetting;
