import { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import QRCode from "react-qr-code";
import { stratis } from "viem/chains";

type Props = { isOpen: boolean; onClose: () => void; address: string; contract?: string };

const WalletQRCodeModal = ({ isOpen, onClose, address, contract }: Props) => {
  const [isCopied, setIsCopied] = useState(false);
  const [tokenContract, setTokenContract] = useState("");

  useEffect(() => {
    setTokenContract(contract || "");
  }, [contract]);

  return (
    <div
      className={`z-50 p-8 bg-primary-500/90 backdrop-blur-md fixed w-screen h-screen top-0 left-0 flex items-center justify-center transition-all duration-300 ${
        isOpen ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"
      }`}
    >
      <div
        className={`max-w-400 w-full mx-auto flex flex-col gap-32 bg-primary-400 shadow-sm rounded-8 p-24 items-center border border-white/5 relative transition-all duration-300 ${
          isOpen ? "translate-y-0" : "translate-y-80"
        }`}
      >
        <button className="text-24 cursor-pointer absolute right-12 top-12 text-primary-200" onClick={onClose}>
          <Icon icon={"material-symbols-light:close-rounded"} />
        </button>
        <div className="border-b border-white/5 font-medium text-white pb-8 p-4 w-full text-18 text-center">
          Your Wallet Address
        </div>

        <div className="text-center text-14 break-all font-light">{address}</div>

        <div className="w-full aspect-square max-w-160">
          <QRCode
            className="rounded-8 relative  border-4 border-theme-main"
            value={!tokenContract ? address || "" : `ethereum:${tokenContract}@${stratis.id}`}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          />
        </div>

        <button
          className="m-button-primary w-full justify-center mt-auto"
          onClick={() => {
            if (isCopied) return;
            navigator.clipboard.writeText(address).then(() => {
              setIsCopied(true);
              setTimeout(() => setIsCopied(false), 1000);
            });
          }}
        >
          {isCopied ? "Copied" : "Copy Address"}
        </button>
      </div>
    </div>
  );
};

export default WalletQRCodeModal;
