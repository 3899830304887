"use client";
import { sumArray } from "utils/number.util";
import React, { useState, useMemo } from "react";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { IAsset } from "types/portfolio.type"; //eslint-disable-line

// eslint-disable-next-line react/display-name
const MemoizedPieChart = React.memo(
  // eslint-disable-next-line
  ({ data, onCellHover }: { data: IAsset[]; onCellHover: (value: null | IAsset) => void }) => (
    <ResponsiveContainer className={`w-full h-full`}>
      <PieChart className="w-full h-full">
        <Pie
          dataKey="value"
          data={data}
          cx="50%"
          cy="50%"
          innerRadius="96%"
          outerRadius="100%"
          paddingAngle={2}
          fill="#18191B"
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell
              className="cursor-pointer hover:fill-theme-main u-transition-color"
              key={`cell-${index}`}
              onMouseEnter={() => onCellHover(entry)}
              onMouseLeave={() => onCellHover(null)}
            />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  )
);

const AssetsChart = ({ data }: { data: IAsset[] }) => {
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const totalValue = useMemo(() => sumArray(data.map((item) => item.balance * item.price)), [data]);
  const filteredData = useMemo(
    () =>
      data
        .map((item) => {
          return { ...item, value: item.balance * item.price };
        })
        .filter((item) => {
          return item.balance !== 0;
        }),
    [data]
  );

  return (
    <div className="relative w-full max-w-450 aspect-square">
      <div
        className={`absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex items-center flex-col gap-12 ${
          selectedItem ? "animate-fadeIn" : ""
        } `}
      >
        <span className="text-24 xs:text-16 font-semibold text-primary-200/60 uppercase">
          {selectedItem?.key || "TOTAL"}
        </span>
        <span className={`text-white font-medium text-28 xs:text-24`}>
          $ {(selectedItem?.value || totalValue).toLocaleString()}
        </span>
        <div className={`xs:text-14`}>
          {selectedItem
            ? `${selectedItem?.balance.toLocaleString()} ${selectedItem?.key || ""}`
            : `${filteredData?.length} Assets`}
        </div>
      </div>
      <MemoizedPieChart data={filteredData} onCellHover={setSelectedItem} />
      {!filteredData?.length && (
        <div className="absolute w-full h-full top-0 left-0 border-8 rounded-full border-primary-300"></div>
      )}
    </div>
  );
};

export default AssetsChart;
