import { parseEther } from "viem";
import { useUserRegisterationStatus } from "./useUserRegisterationStatus";
import { useContractBalance } from "./useContractBalance";
import { useTotalCollateralAmount } from "./useTotalCollateralAmount";
import { useTotalBlockShares } from "./useTotalBlockShares";
import { useUserSinceLastClaim } from "./useUserSinceLastClaim";
import { RegistrationStatus } from "types/masternode.type";

export const useUserReward = () => {
  const userStatus = useUserRegisterationStatus();
  const sinceLastClaim = useUserSinceLastClaim();
  const contractBalance = useContractBalance();
  const totalCollateralAmount = useTotalCollateralAmount();
  const totalBlockShares = useTotalBlockShares();

  try {
    if (userStatus !== RegistrationStatus.REGISTERED || totalBlockShares === BigInt(0) || sinceLastClaim === 0) {
      return BigInt(0);
    }
    const value =
      ((parseEther(contractBalance.toString()) - parseEther(totalCollateralAmount.toString())) * sinceLastClaim) /
      totalBlockShares;
    return value || 0;
  } catch (error) {
    return 0;
  }
};
