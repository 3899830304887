"use client";
import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { isWalletAddress } from "utils/string.util";
import { getTokenInfo } from "hooks/settings/useGetTokenInfo";
import { ITokenInfo } from "types/settings.type";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (token: ITokenInfo) => void; //eslint-disable-line
  tokens: ITokenInfo[];
};

const TokenImportModal = ({ isOpen, onClose, onSubmit, tokens }: Props) => {
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenName, setTokenName] = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [tokenDecimals, setTokenDecimals] = useState("");
  const [tokenAddressError, setTokenAddressError] = useState("");
  const [isValidTokenAddress, setIsValidTokenAddress] = useState(false);
  const [isPendingInfo, setIsPendingInfo] = useState(false);

  const handleChangeTokenAddress = async (e: string) => {
    setTokenAddress(e);
    if (!isWalletAddress(e)) {
      setTokenAddressError("This is an invalid address.");
      setIsValidTokenAddress(false);
      setTokenName("");
      return;
    }
    setTokenAddressError("");
    setIsPendingInfo(true);
    const tokenInfo = await getTokenInfo(e);
    setIsPendingInfo(false);
    if (!tokenInfo) {
      setTokenAddressError("This is not a token address.");
      return;
    }
    setTokenSymbol(tokenInfo?.symbol);
    setTokenDecimals(tokenInfo?.decimals?.toString());
    setIsValidTokenAddress(true);
  };

  const handleImportToken = () => {
    const tokenInfo = { tokenAddress, tokenSymbol, tokenName, tokenDecimals };
    if (tokens.map((item) => item.tokenAddress).includes(tokenInfo.tokenAddress)) {
      setTokenAddressError("Duplicated address.");
      return;
    }
    onSubmit(tokenInfo);
    handleResetForm();
  };

  const handleResetForm = () => {
    setTokenAddress("");
    setTokenName("");
    setTokenSymbol("");
    setTokenDecimals("");
    setTokenAddressError("");
    setIsValidTokenAddress(false);
    setIsPendingInfo(false);
    onClose();
  };

  return (
    <div
      className={`z-50 p-8 bg-primary-500/90 backdrop-blur-md fixed w-screen h-screen top-0 left-0 flex items-center justify-center transition-all duration-300 ${
        isOpen ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"
      }`}
    >
      <div
        className={`max-w-400 min-h-520 w-full mx-auto flex flex-col gap-16 bg-primary-400 shadow-sm rounded-8 p-24 items-center border border-white/5 relative transition-all duration-300 ${
          isOpen ? "translate-y-0" : "translate-y-80"
        }`}
      >
        <button className="text-24 cursor-pointer absolute right-12 top-12 text-primary-200" onClick={handleResetForm}>
          <Icon icon={"material-symbols-light:close-rounded"} />
        </button>
        <div className="border-b border-white/5 font-medium text-white pb-8 p-4 w-full text-18 text-center">
          Import Token
        </div>
        <div className="text-14 bg-theme-main/10 text-theme-main p-12 border-l-4 border-theme-main">
          StraxDashboard supports only Stratis Mainnet tokens. Please import token manually and make sure you trust it.
        </div>
        <div className="flex flex-col gap-4 w-full">
          <label className="m-label">Contract Address</label>
          <input
            className="m-input"
            value={tokenAddress}
            onChange={(e) => handleChangeTokenAddress(e.target.value)}
          ></input>
          <span className="text-red-600 text-13">{tokenAddressError}</span>
        </div>

        {isPendingInfo && (
          <>
            <Icon icon={"eos-icons:loading"} className="text-24" />
          </>
        )}
        {isValidTokenAddress && (
          <>
            <div className="flex flex-col gap-4 w-full">
              <label className="m-label">Token Name</label>
              <input className="m-input" value={tokenName} onChange={(e) => setTokenName(e.target.value)}></input>
            </div>
            <div className="flex flex-col gap-4 w-full">
              <label className="m-label">Token Symbol</label>
              <input className="m-input" value={tokenSymbol} readOnly></input>
            </div>
            <div className="flex flex-col gap-4 w-full">
              <label className="m-label">Token Decimal</label>
              <input className="m-input" value={tokenDecimals} readOnly></input>
            </div>
          </>
        )}

        <button
          disabled={!isValidTokenAddress || !tokenName}
          className="m-button-primary w-full justify-center mt-auto"
          onClick={handleImportToken}
        >
          Import Token
        </button>
      </div>
    </div>
  );
};

export default TokenImportModal;
