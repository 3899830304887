import AppHeader from "../components/AppHeader";
import AppLinkFooter from "../components/AppLinkFooter";
import WrapContent from "./components/WrapContent";

const WrapPage = () => {
  return (
    <section className="p-32 lg:p-8 lg:py-92 min-h-screen flex flex-col justify-between lg:justify-center gap-32">
      <AppHeader title="Wrap" />

      <div className="max-w-600 w-full mx-auto ">
        <WrapContent />
      </div>
      <AppLinkFooter link="https://wrap.stratisevm.com/" />
    </section>
  );
};

export default WrapPage;
