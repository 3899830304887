import { useState, useEffect } from "react";

const useTokenPrice = (tokenName: string) => {
  const [price, setPrice] = useState(0);
  const apiUrl = `https://min-api.cryptocompare.com/data/price?fsym=${tokenName}&tsyms=USD`;

  useEffect(() => {
    const fetchPrice = async () => {
      try {
        const response = await fetch(apiUrl);
        const data = await response.json();
        setPrice(data.USD);
      } catch (error) {
        setPrice(0);
      }
    };

    fetchPrice();
    const interval = setInterval(fetchPrice, 60000);
    return () => clearInterval(interval);
  }, [apiUrl]);
  return price;
};

export default useTokenPrice;
