import { useEffect } from "react";
import { formatEther } from "viem";
import { stratis } from "viem/chains";
import { useAccount, useBalance, useBlockNumber } from "wagmi";

export const useUserBalance = () => {
  const { address, chain } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const { data: result, refetch } = useBalance({ address, chainId: chain?.id || stratis.id });
  useEffect(() => {
    refetch();
  }, [blockNumber, refetch]);
  try {
    return parseFloat(formatEther((result?.value as any) || 0));
  } catch (error) {
    return 0;
  }
};
