import { useUserType } from "./useUserType";
import { UserType } from "types/masternode.type";
import {
  COLLATERAL_AMOUNT_10K,
  COLLATERAL_AMOUNT,
  COLLATERAL_AMOUNT_50K,
  COLLATERAL_MAIN_AMOUNT,
} from "config/constants";
import { stratis } from "viem/chains";
import { useAccount } from "wagmi";

export function useUserCollateral() {
  const type = useUserType();
  const { chain } = useAccount();
  if (type === UserType.LEGACY_10K) {
    return COLLATERAL_AMOUNT_10K;
  } else if (type === UserType.LEGACY_50K) {
    return COLLATERAL_AMOUNT_50K;
  } else if (type === UserType.REGULAR) {
    return chain?.id === stratis.id ? COLLATERAL_MAIN_AMOUNT : COLLATERAL_AMOUNT;
  }

  return 0;
}
