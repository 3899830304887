import { formatEther } from "viem";
import { useEffect } from "react";
import { stratis } from "viem/chains";
import { useAccount, useBalance, useBlockNumber } from "wagmi";
import { MASTERNODE_ADDRESS } from "config/constants";

export const useContractBalance = () => {
  const { chain } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const { data: result, refetch } = useBalance({ address: MASTERNODE_ADDRESS, chainId: chain?.id || stratis.id });
  useEffect(() => {
    refetch();
  }, [blockNumber, refetch]);
  try {
    return parseFloat(formatEther(BigInt(result?.value || 0))).toFixed(4);
  } catch (error) {
    return 0;
  }
};
