import { useCallback } from "react";
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import ABI from "config/abis/masterNode.json";
import { MASTERNODE_ADDRESS } from "config/constants";

export function useCompleteWithdrawal() {
  const { address, chain } = useAccount();

  const { data: hash, isPending, writeContract } = useWriteContract();
  const { isLoading } = useWaitForTransactionReceipt({ hash });

  const completeWithdrawal = useCallback(async () => {
    if (!address || !chain) {
      console.log("error");
      return;
    }

    writeContract({
      abi: ABI,
      address: MASTERNODE_ADDRESS,
      functionName: "completeWithdrawal",
      account: address,
      chain,
    });
  }, [address, chain, writeContract]);

  return { pending: isPending || isLoading, completeWithdrawal };
}
