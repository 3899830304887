import { useCallback } from "react";
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from "wagmi";
import { useUserRegisterationStatus } from "./useUserRegisterationStatus";
import { RegistrationStatus } from "types/masternode.type";
import ABI from "config/abis/masterNode.json";
import { MASTERNODE_ADDRESS } from "config/constants";

export function useClaimRewards() {
  const { address, chain } = useAccount();
  const status = useUserRegisterationStatus();

  const { data: hash, isPending, writeContract } = useWriteContract();
  const { isLoading } = useWaitForTransactionReceipt({ hash });

  const claimRewards = useCallback(async () => {
    if (!address || status !== RegistrationStatus.REGISTERED || !chain) {
      return;
    }

    writeContract({
      abi: ABI,
      address: MASTERNODE_ADDRESS,
      functionName: "claimRewards",
      account: address,
      chain,
    });
  }, [address, status, chain, writeContract]);

  return { pending: isPending || isLoading, claimRewards };
}
