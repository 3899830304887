"use client";
import { useState } from "react";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount, useSwitchChain } from "wagmi";
import NetworkWarning from "app/components/NetworkWarning";
import { useNativeBalance } from "hooks/wrap/useNativeBalance";
import { useWrappedBalance } from "hooks/wrap/useWrappedBalance";
import { stratis } from "viem/chains";
import { useWrapSTRAX } from "hooks/wrap/useWrapSTRAX";
import { parseEther } from "viem";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useUnwrapSTRAX } from "hooks/wrap/useUnwrapSTRAX";

const WrapContent = () => {
  const [isWrap, setIsWrap] = useState(true);
  const [amount, setAmount] = useState("");
  const { address, chain } = useAccount();
  const { switchChain } = useSwitchChain();
  const { openConnectModal } = useConnectModal();
  const balanceNative = useNativeBalance();
  const balanceWrapped = useWrappedBalance();
  const { pending: pendingWrapSTRAX, wrapStrax } = useWrapSTRAX();
  const { pending: pendingUnwrapSTRAX, unwrapStrax } = useUnwrapSTRAX();

  return (
    <div className=" g-border-pink rounded-16 p-1">
      <div className="w-full flex flex-col gap-24 bg-primary-400 shadow-sm rounded-16 p-24 sm:p-16 border border-white/5">
        <NetworkWarning />
        <div className="w-full p-6 flex items-center gap-6 rounded-6 bg-primary-500 text-center font-medium">
          <div
            className={`w-full cursor-pointer p-12 rounded-4 ${isWrap ? "bg-theme-main/10 text-theme-main" : ""}`}
            onClick={() => setIsWrap(true)}
          >
            Wrap
          </div>
          <div
            className={`w-full cursor-pointer p-12 rounded-4 ${!isWrap ? "bg-theme-main/10 text-theme-main" : ""}`}
            onClick={() => setIsWrap(false)}
          >
            Unwrap
          </div>
        </div>
        <div className="w-full flex flex-col gap-8">
          <div className="flex items-center justify-between text-14">
            <span>Balance</span>
            <span
              className="cursor-pointer"
              onClick={() => {
                setAmount(isWrap ? balanceNative.toString() : balanceWrapped.toString());
              }}
            >
              {isWrap ? balanceNative.toLocaleString() : balanceWrapped.toLocaleString()} {isWrap ? "Strax" : "wStrax"}
            </span>
          </div>
          <input
            className="w-full border border-primary-200/30 rounded-4 bg-transparent p-12 u-transition-color focus:border-primary-200/50 placeholder:text-primary-100/20"
            placeholder="0.0"
            value={amount}
            pattern="^([0-9]+(?:[.][0-9]*)?)$"
            inputMode="decimal"
            onChange={(e) => {
              if (!e.target.validity.valid) {
                return;
              }
              setAmount(e.target.value);
            }}
          ></input>
        </div>

        <div>
          {!address ? (
            <button className="m-button-primary w-full justify-center" onClick={openConnectModal}>
              Connect
            </button>
          ) : !chain ? (
            <button
              className="m-button-secondary w-full justify-center"
              onClick={() => switchChain({ chainId: stratis.id })}
            >
              Switch Network
            </button>
          ) : isWrap ? (
            <button
              disabled={!parseFloat(amount) || parseFloat(amount) > balanceNative || pendingWrapSTRAX}
              className="m-button-primary w-full justify-center"
              onClick={() => wrapStrax(parseEther(amount))}
            >
              Wrap
              {pendingWrapSTRAX && <Icon icon="line-md:loading-twotone-loop" className="text-16" />}
            </button>
          ) : (
            <button
              disabled={!parseFloat(amount) || parseFloat(amount) > balanceWrapped || pendingUnwrapSTRAX}
              className="m-button-primary w-full justify-center"
              onClick={() => unwrapStrax(parseEther(amount))}
            >
              Unwrap
              {pendingUnwrapSTRAX && <Icon icon="line-md:loading-twotone-loop" className="text-16" />}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WrapContent;
