import { useAccount } from "wagmi";

import Card from "./Card";
import { useUserBalance } from "hooks/masternode/useUserBalance";
import { useUserReward } from "hooks/masternode/useUserReward";
import { useUserCollateral } from "hooks/masternode/useUserCollateral";
import { useUserLastClaimedBlock } from "hooks/masternode/useUserLastClaimedBlock";
import { formatEther } from "viem";

const PrivateCards = () => {
  const { address } = useAccount();

  const userBalance = useUserBalance();
  const userReward = useUserReward();
  const userCollateral = useUserCollateral();
  const userLastClaimedBlock = useUserLastClaimedBlock();
  return (
    <>
      {address && (
        <>
          <div className="flex md:flex-col gap-12">
            <Card title="Balance" text={`${userBalance.toFixed(4)} STRAX`} />
            <Card title="Reward" text={`${parseFloat(formatEther(BigInt(userReward))).toFixed(4)} STRAX`} />
          </div>
          <div className="flex md:flex-col gap-12">
            <Card title="Collateral amount" text={`${userCollateral} STRAX`} />
            <Card title="Last claimed block" text={`${userLastClaimedBlock}`} />
          </div>
        </>
      )}
    </>
  );
};

export default PrivateCards;
