import { useMemo, useState } from "react";
import { IAsset } from "types/portfolio.type"; //eslint-disable-line
import { sumArray } from "utils/number.util";
import WalletQRCodeModal from "app/components/WalletQRCodeModal";
import { useAccount } from "wagmi";
import SendTokenModal from "app/components/SendTokenModal";
import { Link } from "react-router-dom";

const AssetsTable = ({ data }: { data: IAsset[] }) => {
  const [isWalletInfoModal, setIsWalletInfoModal] = useState(false);
  const [isSendModal, setIsSendModal] = useState(false);
  const [selectedReceiveCA, setSelectedReceiveCA] = useState("");
  const [selectedSendItem, setSelectedSendItem] = useState<IAsset | undefined>(undefined);
  const { address } = useAccount();

  const totalValue = useMemo(() => sumArray(data.map((item) => item.balance * item.price)), [data]);
  const filteredData = useMemo(() => {
    return data.map((item) => {
      return { ...item, percent: !totalValue ? 0 : ((item.balance * item.price) / totalValue) * 100 };
    });
    // .sort((a, b) => b.percent - a.percent);
  }, [data, totalValue]);
  return (
    <>
      <div className="w-full overflow-x-auto min-h-240 flex flex-col gap-24 md:gap-16 items-center">
        <div className=" g-border-pink rounded-16 overflow-hidden  lg:hidden">
          <div className="p-16 g-bg-box rounded-16 overflow-hidden">
            <table className="w-full table-fixed text-14">
              <thead>
                <tr className="font-medium text-primary-200 text-left border-b border-white/5 p-16">
                  <th className="p-12 w-40">#</th>
                  <th className="p-12 w-120">Name</th>
                  <th className="p-12 w-120">Price</th>
                  <th className="p-12 w-180">Balance</th>
                  <th className="p-12 w-150">Value</th>
                  <th className="p-12 w-100">Portfolio %</th>
                  <th className="p-12 w-160"></th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((item, i) => {
                  return (
                    <tr key={i} className="font-light text-left border-b border-[#ffffff07] last:border-none">
                      <td className="p-12">{i + 1}</td>
                      <td className="p-12">{item.tokenName}</td>
                      <td className="p-12">${item.price}</td>
                      <td className="p-12">
                        {item.balance.toLocaleString()} <span className="text-primary-200">{item.key}</span>
                      </td>
                      <td className="p-12">${(item.balance * item.price).toLocaleString()}</td>
                      <td className="p-12">{item.percent.toLocaleString()}%</td>
                      <td className="p-12">
                        <div className="flex items-center justify-end gap-8 w-full">
                          <button
                            className="text-13 rounded-4 bg-primary-300 px-12 py-6 border border-white/5"
                            onClick={() => {
                              setSelectedSendItem(item);
                              setIsSendModal(true);
                            }}
                          >
                            Send
                          </button>
                          <button
                            className="text-13 rounded-4 bg-primary-300 px-12 py-6 border border-white/5"
                            onClick={() => {
                              setSelectedReceiveCA(item?.tokenContract || "");
                              setIsWalletInfoModal(true);
                            }}
                          >
                            Receive
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex-col gap-8 hidden lg:flex w-full mx-auto max-w-540">
          {filteredData.map((item, i) => {
            return (
              <div key={i} className=" text-left g-bg-box border border-white/5 rounded-6  p-12">
                <div className="flex justify-between gap-8">
                  <div className=" flex flex-col gap-4">
                    <div className="font-medium">{item.tokenName}</div>
                    <div className="text-primary-200 text-14">${item.price}</div>
                  </div>
                  <div className="text-right flex flex-col gap-4">
                    <div className="font-thin">
                      {item.balance.toLocaleString()} {item.key}
                    </div>
                    <div className="text-primary-200 text-14">
                      ${(item.balance * item.price).toLocaleString()} ({item.percent.toLocaleString()}%)
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-end gap-8 w-full mt-12">
                  <button
                    className="text-13 rounded-4 bg-primary-300 px-12 py-6 text-primary-100 border border-white/5"
                    onClick={() => {
                      setSelectedSendItem(item);
                      setIsSendModal(true);
                    }}
                  >
                    Send
                  </button>
                  <button
                    className="text-13 rounded-4 bg-primary-300 px-12 py-6 text-primary-100 border border-white/5"
                    onClick={() => {
                      setSelectedReceiveCA(item.tokenContract || "");
                      setIsWalletInfoModal(true);
                    }}
                  >
                    Receive
                  </button>
                </div>
              </div>
            );
          })}
        </div>
        <Link to={"/settings"} className="m-button-primary">
          Import Token
        </Link>
      </div>
      <WalletQRCodeModal
        isOpen={isWalletInfoModal}
        onClose={() => setIsWalletInfoModal(false)}
        address={address as string}
        contract={selectedReceiveCA}
      />
      <SendTokenModal isOpen={isSendModal} onClose={() => setIsSendModal(false)} asset={selectedSendItem} />
    </>
  );
};

export default AssetsTable;
