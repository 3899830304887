import TokenSetting from "./components/TokenSetting";

const SettingsPage = () => {
  return (
    <section className="p-8 lg:pt-80 min-h-screen flex flex-col justify-center gap-32">
      <TokenSetting />
    </section>
  );
};

export default SettingsPage;
