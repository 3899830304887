import { stratis } from "viem/chains";
import { erc20Abi, formatUnits } from "viem";
import { readContract } from "@wagmi/core";
import { wagmiConfig } from "App";

export const getCustomTokenBalance = async (address: string, tokenAddress: string, decimals: number) => {
  const data = await readContract(wagmiConfig, {
    address: tokenAddress as `0x${string}`,
    abi: erc20Abi,
    functionName: "balanceOf",
    chainId: stratis.id,
    args: [address as `0x${string}`],
  });
  try {
    return parseFloat(formatUnits(BigInt(data || 0), decimals));
  } catch (error) {
    return 0;
  }
};
