"use client";
import { useState } from "react";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount, useDisconnect, useSwitchChain } from "wagmi";
import Jazzicon, { jsNumberForAddress } from "react-jazzicon";
import { shortenString } from "utils/string.util";
import { Icon } from "@iconify/react/dist/iconify.js";
import useClickOutside from "hooks/global/useClickOutside";
import WalletQRCodeModal from "./WalletQRCodeModal";
import toast from "react-hot-toast";

type Props = {
  title: string;
};
const AppHeader = ({ title }: Props) => {
  const [showNetworkMenu, setShowNetworkMenu] = useState(false);
  const [isWalletInfoModal, setIsWalletInfoModal] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { chains, switchChain } = useSwitchChain();
  const { address, chainId, chain } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { disconnect } = useDisconnect();

  return (
    <>
      <header className="lg:fixed z-10 w-full lg:bottom-0 lg:p-8 lg:left-0">
        <div className="flex items-center px-24 py-16 lg:px-8 rounded-8 gap-16 xs:gap-8 g-bg-box border border-white/5 lg:border-theme-main lg:shadow-[0_0_12px_-5px_#ba6bff] w-full lg:justify-between">
          <span className="g-text-pink font-medium mr-auto lg:hidden">{title}</span>
          {address ? (
            <div className="text-14 flex items-center gap-8">
              <Jazzicon diameter={24} seed={jsNumberForAddress(address)} />
              <span className="block xs:hidden text-13">{shortenString(address, 6, 4)}</span>
              <span className="hidden xs:block text-13">{shortenString(address, 4, 2)}</span>
              <button
                className="p-4 rounded-4 bg-primary-300 border border-white/5"
                onClick={() => {
                  if (isCopied) return;
                  navigator.clipboard.writeText(address).then(() => {
                    setIsCopied(true);
                    toast.success("Copied successfully.");
                    setTimeout(() => setIsCopied(false), 1000);
                  });
                }}
              >
                <Icon
                  icon={isCopied ? "material-symbols-light:check" : "material-symbols-light:content-copy-rounded"}
                  className={`text-20 ${isCopied ? "text-green-400" : ""}`}
                />
              </button>
              <button
                className="p-4 rounded-4 bg-primary-300  border border-white/5"
                onClick={() => setIsWalletInfoModal(true)}
              >
                <Icon icon={"material-symbols-light:qr-code-rounded"} className="text-20" />
              </button>
              <button className="p-4 rounded-4 bg-primary-300  border border-white/5" onClick={() => disconnect()}>
                <Icon icon={"material-symbols-light:logout"} className="text-20" />
              </button>
            </div>
          ) : (
            <button
              className="px-8 py-4 text-14 rounded-4 bg-primary-300  border border-white/5 lg:mx-auto flex items-center gap-8"
              onClick={openConnectModal}
            >
              Connect Wallet
              <div className="w-7 h-7 bg-theme-main rounded-full"></div>
            </button>
          )}
          <div
            className={`relative text-14 shrink-0 ${address ? "block" : "hidden"}`}
            ref={useClickOutside(() => {
              setShowNetworkMenu(false);
            })}
          >
            <button
              className="pl-12 pr-7 py-4 rounded-4 bg-primary-300 flex items-center gap-4 text-14 border border-white/5"
              onClick={() => setShowNetworkMenu(!showNetworkMenu)}
            >
              <span className="block xs:hidden">{chain?.name || "Wrong Network"}</span>
              <span className="hidden xs:block">
                {chain?.name.replace("Mainnet", "").replace("Testnet", "") || "Wrong "}
              </span>
              <Icon className="text-20" icon={"material-symbols-light:keyboard-arrow-down-rounded"} />
            </button>
            <div
              className={`text-14 absolute right-0 top-full lg:top-auto lg:mt-0 lg:mb-8 lg:bottom-full mt-8 bg-primary-300 shadow-md p-12 rounded-8 border border-white/5 w-160 flex flex-col gap-6 ${
                showNetworkMenu ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
              }`}
            >
              {chains.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="whitespace-nowrap cursor-pointer flex items-center gap-8"
                    onClick={() => {
                      switchChain({ chainId: item.id });
                      setShowNetworkMenu(false);
                    }}
                  >
                    {item.name}
                    {item.id === chainId && <div className="w-8 h-8 rounded-full bg-green-500 ml-auto"></div>}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </header>{" "}
      <WalletQRCodeModal
        isOpen={isWalletInfoModal}
        onClose={() => setIsWalletInfoModal(false)}
        address={address as string}
      />
    </>
  );
};

export default AppHeader;
