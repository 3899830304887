const SwapPage = () => {
  return (
    <section className="p-32 lg:pt-80 lg:p-8 min-h-screen flex flex-col justify-center items-center gap-32">
      <img src="/visit.png" alt="" />
      <div className="flex flex-col gap-8 text-center">
        <span className="text-white font-medium text-24">Visit Official Swap Website</span>
        <p className="text-primary-200">Please visit official website to swap your stratis tokens</p>
      </div>
      <a target="_blank" href="https://straxswap.com" className="m-button-primary">
        Visit Website
      </a>
    </section>
  );
};

export default SwapPage;
