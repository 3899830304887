const getTokenPrice = async (tokenName: string) => {
  const apiUrl = `https://min-api.cryptocompare.com/data/price?fsym=${tokenName}&tsyms=USD`;

  try {
    const response = await fetch(apiUrl);
    const data = await response.json();
    return data.USD || 0;
  } catch (error) {
    return 0;
  }
};

export default getTokenPrice;
