import SvgLogo from "assets/SvgLogo";
import { Link } from "react-router-dom";

const MainPage = () => {
  return (
    <section className="p-32 lg:pt-80 lg:p-8 min-h-screen flex flex-col justify-center items-center gap-32">
      <SvgLogo className="text-theme-main w-100 h-auto" />
      <div className="flex flex-col gap-8 text-center">
        <span className="text-white font-medium text-24">Strax Dashboard</span>
        <p className="text-primary-200">Enjoy the all in one stratis assets management platform now</p>
      </div>
      <Link to="/portfolio" className="m-button-primary">
        Get Started
      </Link>
    </section>
  );
};

export default MainPage;
