import { useEffect } from "react";
import { stratis } from "viem/chains";
import { useAccount, useBlockNumber, useReadContract } from "wagmi";
import ABI from "config/abis/masterNode.json";
import { RegistrationStatus } from "types/masternode.type";
import { MASTERNODE_ADDRESS } from "config/constants";

export const useUserRegisterationStatus = () => {
  const { address, chain } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const { data, refetch } = useReadContract({
    address: MASTERNODE_ADDRESS,
    abi: ABI,
    functionName: "registrationStatus",
    chainId: chain?.id || stratis.id,
    args: [address],
  });

  useEffect(() => {
    refetch();
  }, [blockNumber, refetch]);

  try {
    if (!address) return RegistrationStatus.UNREGISTERED;
    return data;
  } catch (error) {
    return RegistrationStatus.UNREGISTERED;
  }
};
