import { useEffect } from "react";
import { auroria, stratis } from "viem/chains";
import { useAccount, useBlockNumber, useReadContract } from "wagmi";
import ABI from "config/abis/wstrax.json";
import { USDC_AURORIA_ADDRESS, USDC_STRATIS_ADDRESS } from "config/constants";
import { formatUnits } from "viem";

export const useUSDCBalance = () => {
  const { address, chain } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const { data: result, refetch } = useReadContract({
    address: chain?.id === auroria.id ? USDC_AURORIA_ADDRESS : USDC_STRATIS_ADDRESS,
    abi: ABI,
    functionName: "balanceOf",
    chainId: chain?.id || stratis.id,
    args: [address],
  });
  useEffect(() => {
    refetch();
  }, [blockNumber, refetch]);
  try {
    return parseFloat(formatUnits(BigInt((result as number) || 0), 6));
  } catch (error) {
    return 0;
  }
};
