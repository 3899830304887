import { WRAP_AURORIA_ADDRESS, WRAP_STRATIS_ADDRESS } from "config/constants";
import { useCallback } from "react";
import { auroria } from "viem/chains";
import { useAccount, useSendTransaction, useWaitForTransactionReceipt } from "wagmi";

export function useWrapSTRAX() {
  const { address, chain } = useAccount();

  const { data: hash, isPending, sendTransaction } = useSendTransaction();
  const { isLoading } = useWaitForTransactionReceipt({ hash });

  const wrapStrax = useCallback(
    async (amount: BigInt) => {
      if (!address || !chain) {
        console.log("error");
        return;
      }
      sendTransaction({
        to: chain?.id === auroria.id ? WRAP_AURORIA_ADDRESS : WRAP_STRATIS_ADDRESS,
        value: amount as bigint,
      });
    },
    [address, chain, sendTransaction]
  );

  return { pending: isPending || isLoading, wrapStrax };
}
