import React from "react";

type Props = {
  title: string;
  text: string;
};

const Card = ({ title, text }: Props) => {
  return (
    <div className="g-bg-box rounded-8 p-20 flex flex-col gap-4 w-full border border-white/5">
      <div className="text-primary-200 text-14">{title}</div>
      <div className="text-20 ">{text}</div>
    </div>
  );
};

export default Card;
