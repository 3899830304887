import { useCallback } from "react";
import { stratis } from "viem/chains";
import { type BaseError, useAccount, useSendTransaction, useWaitForTransactionReceipt } from "wagmi";

export function useSendStrax() {
  const { address, chain } = useAccount();

  const { data: hash, error, isPending, sendTransaction } = useSendTransaction();
  const { isLoading, isSuccess: isConfirmed } = useWaitForTransactionReceipt({ hash });

  const sendStrax = useCallback(
    async (to: string, amount: BigInt) => {
      if (!address || !chain) {
        console.log("error");
        return;
      }
      sendTransaction({
        to: to as `0x${string}`,
        chainId: chain?.id || stratis.id,
        value: amount as bigint,
      });
    },
    [address, chain, sendTransaction]
  );
  const errorMessage = (error as BaseError)?.shortMessage || error?.message;

  return { pending: isPending || isLoading, isConfirmed, error: errorMessage, sendStrax };
}
