import { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { IAsset } from "types/portfolio.type";
import { isWalletAddress } from "utils/string.util";
import { useSendStrax } from "hooks/portfolio/useSendStrax";
import { parseEther, parseUnits } from "viem";
import toast from "react-hot-toast";
import { useSendToken } from "hooks/portfolio/useSendToken";

type Props = { isOpen: boolean; onClose: () => void; asset?: IAsset };

const SendTokenModal = ({ isOpen, onClose, asset }: Props) => {
  const [tokenInfo, setTokenInfo] = useState<IAsset | undefined>(undefined);
  const [toAddress, setToAddress] = useState("");
  const [toAddressError, setToAddressError] = useState("");
  const [sendAmountStr, setSendAmountStr] = useState("");
  const [sendAmountError, setSendAmountError] = useState("");

  const {
    pending: pendingSendStrax,
    isConfirmed: isConfirmedSendStrax,
    error: errorSendStrax,
    sendStrax,
  } = useSendStrax();

  const {
    pending: pendingSendToken,
    isConfirmed: isConfirmedSendToken,
    error: errorSendToken,
    sendToken,
  } = useSendToken();

  const handleChangeAddress = async (e: string) => {
    setToAddress(e);
    if (!isWalletAddress(e)) {
      setToAddressError("This is an invalid address.");
      return;
    }
    setToAddressError(isWalletAddress(e) ? "" : "Invalid wallet address");
  };

  const handleChangeAmount = async (e: string) => {
    setSendAmountStr(e);
    if (parseFloat(e) === 0) setSendAmountError("Invalid amount");
    else if (parseFloat(e) > (asset?.balance || 0)) setSendAmountError("Insufficient balance.");
    else setSendAmountError("");
  };

  const handleReset = () => {
    setToAddress("");
    setToAddressError("");
    setSendAmountStr("");
    setSendAmountError("");
    onClose();
  };

  const handleSend = () => {
    if (!asset?.tokenContract) sendStrax(toAddress, parseEther(sendAmountStr));
    else sendToken(toAddress, parseUnits(sendAmountStr, tokenInfo?.decimals || 18), tokenInfo?.tokenContract || "");
  };

  useEffect(() => {
    setTokenInfo(asset);
  }, [asset]);

  useEffect(() => {
    if (isConfirmedSendStrax === true || isConfirmedSendToken === true) {
      toast.success(`${parseFloat(sendAmountStr)} ${tokenInfo?.key} sent successfully.`);
      handleReset();
    }
  }, [isConfirmedSendStrax, isConfirmedSendToken]); //eslint-disable-line

  useEffect(() => {
    if (!!errorSendStrax || !!errorSendToken) {
      toast.error(errorSendStrax || errorSendToken || "Something went wrong");
    }
  }, [errorSendStrax, errorSendToken]);

  return (
    <div
      className={`z-50 p-8 bg-primary-500/90 backdrop-blur-md fixed w-screen h-screen top-0 left-0 flex items-center justify-center transition-all duration-300 ${
        isOpen ? "pointer-events-auto opacity-100" : "pointer-events-none opacity-0"
      }`}
    >
      <div
        className={`max-w-400 w-full mx-auto flex flex-col gap-24 bg-primary-400 shadow-sm rounded-8 p-24 items-center border border-white/5 relative transition-all duration-300 ${
          isOpen ? "translate-y-0" : "translate-y-80"
        }`}
      >
        <button className="text-24 cursor-pointer absolute right-12 top-12 text-primary-200" onClick={handleReset}>
          <Icon icon={"material-symbols-light:close-rounded"} />
        </button>
        <div className="border-b border-white/5 font-medium text-white pb-8 p-4 w-full text-18 text-center">
          Send Token
        </div>
        <div className="flex flex-col items-center">
          <div className="text-center text-20 font-medium g-text-pink">{tokenInfo?.tokenName}</div>
          <div className="text-center text-14 text-primary-200/50">
            {tokenInfo?.balance.toLocaleString()} {tokenInfo?.key}
          </div>
        </div>

        <div className="w-full flex flex-col gap-16">
          <div className="flex flex-col gap-4 w-full">
            <label className="m-label">Destination</label>
            <input className="m-input" value={toAddress} onChange={(e) => handleChangeAddress(e.target.value)}></input>
            <span className="text-red-600 text-13">{toAddressError}</span>
          </div>

          <div className="flex flex-col gap-4 w-full">
            <div className="flex items-end justify-between">
              <label className="m-label">Amount</label>
              <i className="text-14 cursor-pointer" onClick={() => setSendAmountStr((asset?.balance || 0).toString())}>
                max
              </i>
            </div>
            <input
              className="m-input"
              value={sendAmountStr}
              pattern="^([0-9]+(?:[.][0-9]*)?)$"
              inputMode="decimal"
              onChange={(e) => {
                if (!e.target.validity.valid) {
                  return;
                }
                handleChangeAmount(e.target.value);
              }}
            ></input>
            <span className="text-red-600 text-13">{sendAmountError}</span>
          </div>
        </div>

        <button
          disabled={
            !toAddress ||
            !parseFloat(sendAmountStr) ||
            !!toAddressError ||
            !!sendAmountError ||
            pendingSendStrax ||
            pendingSendToken
          }
          className="m-button-primary w-full justify-center"
          onClick={handleSend}
        >
          Send
          {(pendingSendStrax || pendingSendToken) && <Icon icon="line-md:loading-twotone-loop" className="text-16" />}
        </button>
      </div>
    </div>
  );
};

export default SendTokenModal;
