import { RegistrationStatus } from "types/masternode.type";
import { WITHDRAWAL_DELAY } from "config/constants";
import { useClaimRewards } from "hooks/masternode/useClaimRewards";
import { useCompleteWithdrawal } from "hooks/masternode/useCompleteWithdrawal";
import { useRegisterUser } from "hooks/masternode/useRegisterUser";
import { useStartWithdrawal } from "hooks/masternode/useStartWithdrawl";
import { useUserBalance } from "hooks/masternode/useUserBalance";
import { useUserCollateral } from "hooks/masternode/useUserCollateral";
import { useUserRegisterationStatus } from "hooks/masternode/useUserRegisterationStatus";
import { useUserReward } from "hooks/masternode/useUserReward";
import { useUserSinceLastClaim } from "hooks/masternode/useUserSinceLastClaim";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { stratis } from "viem/chains";
import { useAccount, useSwitchChain } from "wagmi";

const Actions = () => {
  const { address, chain } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { switchChain } = useSwitchChain();

  const userBalance = useUserBalance();
  const userCollateral = useUserCollateral();
  const userRegisterationStatus = useUserRegisterationStatus();
  const userSinceLastClaim = useUserSinceLastClaim();
  const userReward = useUserReward();

  const { pending: pendingRegisterUser, registerUser } = useRegisterUser();
  const { pending: pendingClaimRewards, claimRewards } = useClaimRewards();
  const { pending: pendingStartWithdrawal, startWithdrawal } = useStartWithdrawal();
  const { pending: pendingCompleteWithdrawal, completeWithdrawal } = useCompleteWithdrawal();
  if (!address)
    return (
      <button className="m-button-primary" onClick={openConnectModal}>
        Connect Wallet
      </button>
    );
  if (!chain)
    return (
      <button className="m-button-secondary" onClick={() => switchChain({ chainId: stratis.id })}>
        Switch Network
      </button>
    );
  if (userRegisterationStatus === RegistrationStatus.UNREGISTERED) {
    return (
      <button
        disabled={pendingRegisterUser || userBalance < userCollateral}
        className="m-button-primary"
        onClick={registerUser}
      >
        Register
        {pendingRegisterUser && <Icon icon="line-md:loading-twotone-loop" className="text-16" />}
      </button>
    );
  }
  if (userRegisterationStatus === RegistrationStatus.REGISTERED)
    return (
      <>
        <button
          disabled={userReward === 0 || pendingClaimRewards || pendingStartWithdrawal}
          className="m-button-primary"
          onClick={claimRewards}
        >
          Claim rewards
          {pendingClaimRewards && <Icon icon="line-md:loading-twotone-loop" className="text-16" />}
        </button>
        <button
          disabled={pendingClaimRewards || pendingStartWithdrawal}
          className="m-button-secondary"
          onClick={startWithdrawal}
        >
          Start withdrawal
          {pendingStartWithdrawal && <Icon icon="line-md:loading-twotone-loop" className="text-16" />}
        </button>
      </>
    );
  if (userRegisterationStatus === RegistrationStatus.WITHDRAWING) {
    return (
      <button
        disabled={pendingCompleteWithdrawal || parseFloat(userSinceLastClaim.toString()) < WITHDRAWAL_DELAY}
        className="m-button-secondary"
        onClick={completeWithdrawal}
      >
        Complete withdrawal
        {pendingCompleteWithdrawal && <Icon icon="line-md:loading-twotone-loop" className="text-16" />}
      </button>
    );
  }
  return <></>;
};

export default Actions;
