import { useEffect } from "react";
import { stratis } from "viem/chains";
import { useAccount, useBlockNumber, useReadContract } from "wagmi";
import { MASTERNODE_ADDRESS } from "config/constants";
import MasterNodeABI from "config/abis/masterNode.json";
import { formatEther } from "viem";

export const useTotalCollateralAmount = () => {
  const { chain } = useAccount();
  const { data: blockNumber } = useBlockNumber({ watch: true });
  const { data: result, refetch } = useReadContract({
    address: MASTERNODE_ADDRESS,
    abi: MasterNodeABI,
    functionName: "totalCollateralAmount",
    chainId: chain?.id || stratis.id,
  });
  useEffect(() => {
    refetch();
  }, [blockNumber, refetch]);
  try {
    return formatEther((result as any) || 0);
  } catch (error) {
    return 0;
  }
};
