import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <section className="p-32 min-h-screen flex flex-col justify-center items-center gap-32">
      <img src="/404.png" alt="" />
      <div className="flex flex-col gap-8 text-center">
        <span className="text-white font-medium text-24">Page Not Found</span>
        <p className="text-primary-200">You may have used a broken URL or the page has been removed</p>
      </div>
      <Link to="/portfolio" className="m-button-primary">
        Go to Portfolio
      </Link>
    </section>
  );
};

export default NotFoundPage;
