"use client";
import AppHeader from "../components/AppHeader";
import AppLinkFooter from "../components/AppLinkFooter";
import NetworkWarning from "../components/NetworkWarning";
import Actions from "./components/Actions";
import PrivateCards from "./components/PrivateCards";
import PublicCards from "./components/PublicCards";

const MasterNodePage = () => {
  return (
    <>
      <section className="p-32 lg:p-8 lg:py-92 min-h-screen flex flex-col justify-between gap-32 md:gap-16">
        <AppHeader title="Masternode" />
        <div className="max-w-800 w-full mx-auto flex flex-col gap-12">
          <NetworkWarning />
          <PublicCards />
          <PrivateCards />
          <div className="mt-16 flex items-center justify-center gap-16">
            <Actions />
          </div>
        </div>
        <AppLinkFooter link="https://masternode.stratisevm.com/" />
      </section>
    </>
  );
};

export default MasterNodePage;
