export const SIDEBAR_MENU_ITEMS = [
  {
    icon: "material-symbols-light:account-balance-wallet-outline",
    text: "Portfolio",
    link: "/portfolio",
  },
  {
    icon: "material-symbols-light:network-node",
    text: "Masternode",
    link: "/masternode",
  },
  {
    icon: "material-symbols-light:published-with-changes",
    text: "Wrap",
    link: "/wrap",
  },
  {
    icon: "material-symbols-light:money-bag-outline",
    text: "Staking",
    link: "/staking",
  },
  {
    icon: "material-symbols-light:swap-horiz",
    text: "Swap",
    link: "/swap",
  },
  {
    icon: "material-symbols-light:swap-calls",
    text: "Bridge",
    link: "/bridge",
  },
  {
    icon: "material-symbols-light:settings-outline",
    text: "Settings",
    link: "/settings",
  },
];

export const MASTERNODE_ADDRESS = "0x0000000000000000000000000000000000001002";
export const COLLATERAL_AMOUNT = 25000;
export const COLLATERAL_MAIN_AMOUNT = 1000000;
export const COLLATERAL_AMOUNT_10K = 100000;
export const COLLATERAL_AMOUNT_50K = 500000;
export const WITHDRAWAL_DELAY = 100800;

export const WRAP_STRATIS_ADDRESS = "0xeA705D2DbD8DE7Dc70Db7B531D0F620d9CeE9d18";
export const WRAP_AURORIA_ADDRESS = "0x6f39A32C3E7A54164e1C6E201979aec276B0Da8E";

export const WETH_STRATIS_ADDRESS = "0xeF11f04217d7a78641f6a300a0dE83791961b3b6";
export const WETH_AURORIA_ADDRESS = "0xeF11f04217d7a78641f6a300a0dE83791961b3b6";

export const USDT_STRATIS_ADDRESS = "0xe46f25Af64467c21a01c20Ae0edf94E2Ed934c5C";
export const USDT_AURORIA_ADDRESS = "0xe46f25Af64467c21a01c20Ae0edf94E2Ed934c5C";

export const USDC_STRATIS_ADDRESS = "0xDD0C4bb4b46A1C10D36593E4FA5F76abdB583f7A";
export const USDC_AURORIA_ADDRESS = "0xDD0C4bb4b46A1C10D36593E4FA5F76abdB583f7A";
