import { RainbowKitProvider, getDefaultConfig, darkTheme } from "@rainbow-me/rainbowkit";
import { metaMaskWallet, rainbowWallet, bitgetWallet, walletConnectWallet } from "@rainbow-me/rainbowkit/wallets";
import { Toaster } from "react-hot-toast";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { auroria, stratis } from "viem/chains";

import Sidebar from "app/components/Sidebar";

import "@rainbow-me/rainbowkit/styles.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainPage from "app/page";
import PortfolioPage from "app/portfolio/page";
import WrapPage from "app/wrap/page";
import BridgePage from "app/bridge/page";
import SwapPage from "app/swap/page";
import SettingsPage from "app/settings/page";
import MasterNodePage from "app/masternode/page";
import NotFoundPage from "app/not-found";
import StakingPage from "app/staking/page";

export const wagmiConfig = getDefaultConfig({
  appName: "starxDashboard",
  projectId: "YOUR_PROJECT_ID",
  chains: [auroria, stratis],
  wallets: [
    {
      groupName: "Recommended",
      wallets: [metaMaskWallet, walletConnectWallet, rainbowWallet, bitgetWallet],
    },
  ],
});

const queryClient = new QueryClient();

function App() {
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme()}>
          <Toaster
            toastOptions={{
              className: "!bg-primary-300 !text-primary-100 !text-14",
              duration: 5000,
            }}
          />
          <BrowserRouter>
            <main className="pl-280 lg:pl-0">
              <Sidebar />
              <Routes>
                <Route path="/" Component={MainPage} />
                <Route path="/portfolio" Component={PortfolioPage} />
                <Route path="/masternode" Component={MasterNodePage} />
                <Route path="/wrap" Component={WrapPage} />
                <Route path="/staking" Component={StakingPage} />
                <Route path="/swap" Component={SwapPage} />
                <Route path="/bridge" Component={BridgePage} />
                <Route path="/settings" Component={SettingsPage} />
                <Route path="*" Component={NotFoundPage} />
              </Routes>
            </main>
          </BrowserRouter>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export default App;
