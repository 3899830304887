import Card from "./Card";
import { useContractBalance } from "hooks/masternode/useContractBalance";
import { useTotalCollateralAmount } from "hooks/masternode/useTotalCollateralAmount";
import { useTotalRegisterations } from "hooks/masternode/useTotalRegisterations";

const PublicCards = () => {
  const contractBalance = useContractBalance();
  const totalCollateralAmount = useTotalCollateralAmount();
  const totalRegisterations = useTotalRegisterations();
  return (
    <>
      <Card title="MasterNode contract balance" text={`${contractBalance} STRAX`} />
      <div className="flex md:flex-col gap-12">
        <Card title="Total collateral amount" text={`${totalCollateralAmount} STRAX`} />
        <Card title="Total registerations" text={totalRegisterations.toString()} />
      </div>
    </>
  );
};

export default PublicCards;
