import ABI from "config/abis/wstrax.json";
import { WRAP_AURORIA_ADDRESS, WRAP_STRATIS_ADDRESS } from "config/constants";
import { useCallback } from "react";
import { auroria } from "viem/chains";
import { useAccount, useWaitForTransactionReceipt, useWriteContract } from "wagmi";

export function useUnwrapSTRAX() {
  const { address, chain } = useAccount();

  const { data: hash, isPending, writeContract } = useWriteContract();
  const { isLoading } = useWaitForTransactionReceipt({ hash });

  const unwrapStrax = useCallback(
    async (amount: BigInt) => {
      if (!address || !chain) {
        console.log("error");
        return;
      }
      writeContract({
        abi: ABI,
        address: chain?.id === auroria.id ? WRAP_AURORIA_ADDRESS : WRAP_STRATIS_ADDRESS,
        functionName: "withdraw",
        args: [amount],
        account: address,
        chain,
      });
    },
    [address, chain, writeContract]
  );

  return { pending: isPending || isLoading, unwrapStrax };
}
