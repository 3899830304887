"use client";
import { useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import AppHeader from "app/components/AppHeader";
import AssetsChart from "./AssetsChart";
import AssetsTable from "./AssetsTable";
import { useStraxBalance } from "hooks/portfolio/useStraxBalance";
import { useWstraxBalance } from "hooks/portfolio/useWstraxBalance";
import { IAsset } from "types/portfolio.type";
import { useWETHBalance } from "hooks/portfolio/useWETHBalance";
import { useUSDTBalance } from "hooks/portfolio/useUSDTBalance";
import { useUSDCBalance } from "hooks/portfolio/useUSDCBalance";
import useTokenPrice from "hooks/portfolio/useTokenPrice";
import { useTokenListStorage } from "hooks/settings/useTokenListStorage";
import getTokenPrice from "hooks/portfolio/getTokenPrice";
import { getCustomTokenBalance } from "hooks/portfolio/useCustomTokenBalance";
import {
  USDC_STRATIS_ADDRESS,
  USDT_STRATIS_ADDRESS,
  WETH_STRATIS_ADDRESS,
  WRAP_AURORIA_ADDRESS,
  WRAP_STRATIS_ADDRESS,
} from "config/constants";
import { auroria } from "viem/chains";

const PortfolioContent = () => {
  const { tokens } = useTokenListStorage();
  const { address, chain } = useAccount();
  const { openConnectModal } = useConnectModal();
  const straxBalance = useStraxBalance();
  const wStraxBalance = useWstraxBalance();
  const wEthBalance = useWETHBalance();
  const usdtBalance = useUSDTBalance();
  const usdcBalance = useUSDCBalance();
  const straxPrice = useTokenPrice("STRAX");
  const ethPrice = useTokenPrice("ETH");
  const usdtPrice = useTokenPrice("USDT");
  const usdcPrice = useTokenPrice("USDC");
  const [customData, setCustomData] = useState([]);

  useEffect(() => {
    const getCustomData = async () => {
      const result = await Promise.all(
        tokens.map(async (item) => {
          return {
            key: item.tokenSymbol,
            tokenName: item.tokenName,
            balance: await getCustomTokenBalance(address as string, item.tokenAddress, parseInt(item.tokenDecimals)),
            price: await getTokenPrice(item.tokenSymbol),
            tokenContract: item.tokenAddress,
          };
        })
      );
      setCustomData(result as any);
    };
    getCustomData();
    const interval = setInterval(getCustomData, 5000);
    return () => clearInterval(interval);
  }, [tokens]); //eslint-disable-line

  const staticData: IAsset[] = useMemo(
    () => [
      { key: "STRAX", balance: straxBalance, tokenName: "Strax", price: straxPrice, decimals: 18 },
      {
        key: "WSTRAX",
        balance: wStraxBalance,
        tokenName: "Wrapped Strax",
        price: straxPrice,
        tokenContract: chain?.id === auroria.id ? WRAP_AURORIA_ADDRESS : WRAP_STRATIS_ADDRESS,
        decimals: 18,
      },
      {
        key: "WETH",
        balance: wEthBalance,
        tokenName: "Wrapped Ether",
        price: ethPrice,
        tokenContract: WETH_STRATIS_ADDRESS,
        decimals: 18,
      },
      {
        key: "USDT",
        balance: usdtBalance,
        tokenName: "Stratis USDT",
        price: usdtPrice,
        tokenContract: USDT_STRATIS_ADDRESS,
        decimals: 6,
      },
      {
        key: "USDC",
        balance: usdcBalance,
        tokenName: "Stratis USDC",
        price: usdcPrice,
        tokenContract: USDC_STRATIS_ADDRESS,
        decimals: 6,
      },
    ],
    [
      chain,
      ethPrice,
      straxBalance,
      straxPrice,
      usdcBalance,
      usdcPrice,
      usdtBalance,
      usdtPrice,
      wEthBalance,
      wStraxBalance,
    ]
  );

  const data = useMemo(() => [...staticData, ...customData], [customData, staticData]);

  return (
    <>
      {address ? (
        <>
          <AppHeader title="Portfolio" />
          <div className="w-full flex items-center justify-center p-20 md:p-8">
            <AssetsChart data={data} />
          </div>
          <AssetsTable data={data} />
        </>
      ) : (
        <>
          <div></div>
          <div className="flex flex-col gap-32 items-center">
            <img src="/connect.png" alt="" />
            <div className="flex flex-col gap-8 text-center">
              <span className="text-white font-medium text-24">Connect Your Wallet</span>
              <p className="text-primary-200">You will be able to continue after connecting your wallet</p>
            </div>
            <button className="m-button-primary" onClick={openConnectModal}>
              Connect
            </button>
          </div>
          <div></div>
        </>
      )}
    </>
  );
};

export default PortfolioContent;
