import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";

import SvgLogo from "assets/SvgLogo";
import { SIDEBAR_MENU_ITEMS } from "config/constants";
import { Link, useLocation } from "react-router-dom";

const Sidebar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();

  return (
    <>
      <header className="z-10 items-center justify-between g-bg-box fixed top-0 left-0 w-full p-12 py-20 hidden lg:flex border-b border-white/5">
        <Link to={"/"} className="text-theme-main flex items-center gap-12">
          <SvgLogo />
          <span className="text-20 font-medium  g-text-pink">StraxDashboard</span>
        </Link>
        <button onClick={() => setIsMobileMenuOpen(true)}>
          <Icon icon="material-symbols-light:menu-rounded" className="text-24" />
        </button>
      </header>
      <div
        className={`z-20 hidden lg:block fixed top-0 left-0 w-screen h-screen bg-primary-500/90  backdrop-blur-md transition-all duration-300 ${
          isMobileMenuOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"
        }`}
        onClick={() => setIsMobileMenuOpen(false)}
      ></div>

      <aside
        className={`z-30 g-bg-box fixed left-0 top-0 w-280 py-16 h-screen flex flex-col gap-16 transition-all duration-300 border-r border-white/5 ${
          isMobileMenuOpen ? "lg:left-0" : "lg:-left-full"
        }`}
      >
        <Link
          to={"/"}
          className="text-theme-main flex items-center gap-12 p-20 w-fit"
          onClick={() => setIsMobileMenuOpen(false)}
        >
          <SvgLogo />
          <span className="text-20 font-medium g-text-pink">StraxDashboard</span>
        </Link>

        <nav className="w-full flex flex-col gap-4 flex-grow">
          {SIDEBAR_MENU_ITEMS.map((item) => (
            <Link
              key={item.icon}
              to={item.link}
              className={`px-20 py-12 flex items-center gap-8 border-l-2   last:mt-auto bg-gradient-to-r ${
                location.pathname.includes(item.link)
                  ? "from-theme-main/10 to-theme-main/0 text-theme-main border-theme-main"
                  : "hover:border-white/80 hover:from-white/5 hover:to-white/0 border-transparent"
              }`}
              onClick={() => setIsMobileMenuOpen(false)}
            >
              <Icon icon={item.icon} className="text-20 flex-none" />
              <span>{item.text}</span>
            </Link>
          ))}
        </nav>
      </aside>
    </>
  );
};

export default Sidebar;
